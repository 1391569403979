<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeHeaderIntro } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeHeaderIntro<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <UiContainer :card-container="true">
    <header
      class="relative flex min-h-[400px] items-center overflow-hidden px-6 py-10 text-white md:rounded-lg lg:py-[120px]"
    >
      <div class="absolute inset-0 w-full bg-cover bg-center bg-no-repeat">
        <NuxtImg
          v-if="fields.backgroundImage?.fields"
          class="size-full object-cover"
          :src="fields.backgroundImage?.fields.file?.url"
          :alt="fields.backgroundImage?.fields.title"
          :width="1024"
          loading="lazy"
        />
      </div>
      <div class="relative grid grow grid-cols-12 items-center">
        <div
          class="col-span-12 text-center"
          :class="{
            'md:col-span-10 md:col-start-2 md:text-left lg:col-span-8 lg:col-start-3':
              fields.alignment !== false,
          }"
        >
          <h1
            v-if="!fields.copy && fields.displayTitle"
            class="m-0"
          >
            {{ fields.title }}
          </h1>

          <h2
            v-else-if="fields.copy && fields.displayTitle"
            class="mb-8"
          >
            {{ fields.title }}
          </h2>

          <div v-if="fields.copy && fields.displayTitle">
            {{ fields.copy }}
          </div>

          <div class="flex justify-center md:absolute md:bottom-[-80px] md:right-[40px]">
            <UiButton
              button-type="primary"
              button-style="outline"
              button-theme="white"
              button-icon="slash"
              class="mt-10"
            >
              <RichTextRenderer
                :document="fields.link"
                :node-renderers="nodeRenderers"
              />
            </UiButton>
          </div>
        </div>
      </div>
    </header>
  </UiContainer>
</template>
