import type { Entry } from "contentful";
import type {
  TypeArticleSkeleton,
} from "~/types/contentful";

export const hasTopicOrSubtopic = (
  content: Entry,
): content is Entry<
  TypeArticleSkeleton,
  "WITHOUT_UNRESOLVABLE_LINKS",
  "en-GB"
> => !!content.fields.topicOrSubtopic;

export const hasImage = (
  content: Entry,
): content is Entry<
  TypeArticleSkeleton,
  "WITHOUT_UNRESOLVABLE_LINKS",
  "en-GB"
> => !!content.fields.image;
