<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockText } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeBlockText<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <UiContainer class="block-text relative bg-white">
    <section class="relative grid-cols-12 gap-4 lg:grid">
      <div class="col-span-12 mb-4 lg:col-span-6 lg:flex">
        <h2>
          {{ fields.title }}
        </h2>
      </div>

      <div class="col-span-12 pt-1 lg:col-span-6">
        <div class="rich-text">
          <RichTextRenderer
            :document="fields.description"
            :node-renderers="nodeRenderers"
          />
        </div>

        <UiButton
          v-if="fields.link"
          button-type="secondary"
          button-icon="slash"
          class="mt-10"
        >
          <RichTextRenderer
            :document="fields.link"
            :node-renderers="nodeRenderers"
          />
        </UiButton>
      </div>
    </section>
  </UiContainer>
</template>
