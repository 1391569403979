<script setup lang="ts">
import { Collapse } from "vue-collapsed";
import { google, outlook, ics } from "calendar-link";

const props = defineProps<{
  title: string;
  calendarDescription?: string;
  location?: string;
  startDateTime?: string;
  endDateTime?: string;
}>();

const showCalendar = ref(false);

const event = {
  title: props.title,
  description: props.calendarDescription ?? "",
  start: props.startDateTime
    ? new Date(props.startDateTime).toISOString()
    : new Date().toISOString(),
  end: props.endDateTime ? new Date(props.endDateTime).toISOString() : new Date().toISOString(),
  location: props.location ?? "Online",
};
</script>

<template>
  <UiButton
    button-type="secondary"
    button-icon="plus"
    class="mt-10"
  >
    <button @click="() => (showCalendar = !showCalendar)">
      Add to calendar
    </button>
  </UiButton>

  <Collapse :when="showCalendar">
    <div class="flex flex-col items-center gap-2 pt-4">
      <a
        class="btn--link"
        :href="google(event)"
      >Google</a>
      <a
        class="btn--link"
        :href="outlook(event)"
      >Outlook</a>
      <a
        class="btn--link"
        :href="ics(event)"
      >Apple</a>
    </div>
  </Collapse>
</template>
