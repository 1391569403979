import type { TypeConfiguration } from "~/types/contentful";

export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp();

  if (
    import.meta.client
      && (!nuxtApp.isHydrating || nuxtApp.payload.serverRendered)
  ) {
    return;
  }

  const { data: configData, error } = await useFetch<
    TypeConfiguration<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"]
  >("/api/config", { transform: useCircularProtect });

  if (configData.value) {
    useContentConfig().value = configData.value;
  } else if (error.value) {
    return abortNavigation(error.value);
  }
});
