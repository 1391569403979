import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeArticleTypeSkeleton } from "./TypeArticleType";
import type { TypePageSkeleton } from "./TypePage";
import type { TypeStaffSkeleton } from "./TypeStaff";
import type { TypeSubtopicSkeleton } from "./TypeSubtopic";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeArticleFields = {
  title: EntryFieldTypes.Symbol;
  subtitle?: EntryFieldTypes.Symbol;
  slug: EntryFieldTypes.Symbol;
  articleType: EntryFieldTypes.EntryLink<TypeArticleTypeSkeleton>;
  parent?: EntryFieldTypes.EntryLink<TypePageSkeleton>;
  source?: EntryFieldTypes.Symbol;
  externalLink?: EntryFieldTypes.Symbol;
  image?: EntryFieldTypes.AssetLink;
  hideArticleImage?: EntryFieldTypes.Boolean;
  publishDate: EntryFieldTypes.Date;
  topicOrSubtopic?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeSubtopicSkeleton | TypeTopicSkeleton>>;
  authors?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeStaffSkeleton>>;
  externalAuthor?: EntryFieldTypes.Symbol;
  content?: EntryFieldTypes.RichText;
  relatedContent?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeArticleSkeleton>>;
  metaTitle?: EntryFieldTypes.Symbol;
  metaDescription?: EntryFieldTypes.Text;
};

export type TypeArticleSkeleton = EntrySkeletonType<TypeArticleFields, "article">;
export type TypeArticle<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeArticleSkeleton, Modifiers, Locales>;

export function isTypeArticle<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeArticle<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "article";
}
