<script lang="ts" setup>
import Player, { type Options } from "@vimeo/player";

const props = withDefaults(
  defineProps<{
    playerHeight?: number;
    playerWidth?: number;
    playerClass?: string;
    options?: Options;
    videoId?: number;
    videoUrl?: string;
    loop?: boolean;
    autoplay?: boolean;
    controls?: boolean;
    eventsToEmit?: string[];
  }>(),
  {
    playerHeight: 340,
    playerWidth: 600,
    options: () => ({}),
    loop: false,
    autoplay: true,
    controls: true,
    eventsToEmit: () => [],
  },
);

const playing = ref(false);

const emit = defineEmits([
  "play",
  "playing",
  "pause",
  "ended",
  "timeupdate",
  "progress",
  "seeking",
  "seeked",
  "texttrackchange",
  "chapterchange",
  "cuechange",
  "cuepoint",
  "volumechange",
  "playbackratechange",
  "bufferstart",
  "bufferend",
  "ready",
  "error",
  "loaded",
  "durationchange",
  "fullscreenchange",
  "qualitychange",
  "camerachange",
  "resize",
]);

const playerModel = defineModel<Player>();

const elementRef = ref<HTMLIFrameElement>();

const mergeOptions = ({ id, url }: { id?: number; url?: string }) => {
  const opts: Options = {
    width: props.playerWidth,
    height: props.playerHeight,
    loop: props.loop,
    autoplay: props.autoplay,
    controls: props.controls,
    portrait: false,
    title: false,
    byline: false,
  };

  if (unref(url)) {
    opts.url = unref(url);
  }

  if (unref(id)) {
    opts.id = unref(id);
  }

  return Object.assign(opts, props.options);
};

watch(elementRef, () => {
  if (elementRef.value && !playerModel.value) {
    const player = new Player(
      elementRef.value,
      mergeOptions({ id: props.videoId, url: props.videoUrl }),
    );

    player
      .ready()
      .then(() => {
        if (props.playerClass) {
          elementRef.value
            ?.querySelector("iframe")
            ?.classList.add(...props.playerClass.split(" "));
        }

        player.pause();

        emit("ready", player);

        playerModel.value = player;
      })
      .catch((error: unknown) => {
        emit("error", error, player);
      });

    props.eventsToEmit.forEach((event) => {
      player.on(event, (data) => {
        emit(event as keyof typeof emit, data, player);
      });
    });

    player.on("play", () => (playing.value = true));
    player.on("pause", () => (playing.value = false));
  }
});

onBeforeUnmount(() => {
  playerModel.value?.unload();
  playerModel.value = undefined;
});

watch(toRef(props, "videoId"), (id) =>
  playerModel.value?.loadVideo(mergeOptions({ id })),
);
watch(toRef(props, "videoUrl"), (url) =>
  playerModel.value?.loadVideo(mergeOptions({ url })),
);
watch(toRef(props, "controls"), () =>
  playerModel.value?.loadVideo(
    mergeOptions({ url: props.videoUrl, id: props.videoId }),
  ),
);

const onPlayClick = () => {
  playerModel.value?.play();
};
</script>

<template>
  <div class="relative">
    <div
      ref="elementRef"
      class="vimeo-player"
    />

    <div
      class="absolute left-1/2 top-1/2 z-50 flex h-[66px] w-[98px] -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center transition-all hover:opacity-80"
      :class="{ hidden: playing }"
      @click="onPlayClick"
    >
      <div class="icon-play absolute size-full bg-blue" />
      <NuxtIcon
        name="ussif:play"
        class="relative"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .vimeo-player {
    position: relative;
    width: 100%;

    // height: 0;
    // padding-bottom: 56.25%; /* 16:9 */

    @media screen and (width <= 1023px) {
      height: calc(56.25vw - 24px);
      width: calc(100vw - 48px);
      padding: 0;
      margin: 0;
      max-width: 100%;
    }
  }

  .icon-play {
    mask:
      linear-gradient(-68deg, #fff 72%, transparent 72%) top left,
      linear-gradient(-248deg, #fff 72%, transparent 72%) bottom right;
    mask-repeat: no-repeat;
    mask-composite: intersect;
  }
</style>

<style>
  .vimeo-player iframe {
    border-radius: 8px;
    max-width: 100%;

    @media screen and (width <= 1023px) {
      width: 100%;
      height: 100%;
    }
  }
</style>
