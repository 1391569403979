import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypeBlockMediaTextFields = {
  title?: EntryFieldTypes.Symbol;
  displayTitle?: EntryFieldTypes.Boolean;
  subtitle?: EntryFieldTypes.Symbol;
  mediaPosition?: EntryFieldTypes.Boolean;
  media?: EntryFieldTypes.AssetLink;
  vimeoUrl?: EntryFieldTypes.Symbol;
  text?: EntryFieldTypes.RichText;
  link?: EntryFieldTypes.RichText;
  price?: EntryFieldTypes.Symbol;
  duration?: EntryFieldTypes.Symbol;
  rating?: EntryFieldTypes.Symbol;
};

export type TypeBlockMediaTextSkeleton = EntrySkeletonType<TypeBlockMediaTextFields, "blockMediaText">;
export type TypeBlockMediaText<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeBlockMediaTextSkeleton, Modifiers, Locales>;

export function isTypeBlockMediaText<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeBlockMediaText<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "blockMediaText";
}
