<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeHeaderEvent } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";
import { formatDate, formatTime } from "~/lib/date-time";

const props = defineProps<{
  fields: TypeHeaderEvent<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const formattedStartDate = computed(() =>
  props.fields.startDateTime ? formatDate(new Date(props.fields.startDateTime).toISOString()) : "",
);

const formattedStartTime = computed(() =>
  props.fields.startDateTime ? formatTime(props.fields.startDateTime) : "",
);
</script>

<template>
  <UiContainer>
    <div class="grid grid-cols-12 gap-y-8">
      <div class="col-span-12 md:col-span-6">
        <h1>{{ fields.title }}</h1>

        <TextReadMore
          :text="fields.text"
          :extra-text="fields.extraText"
        />
      </div>

      <CardHeader>
        <h3 class="font-bold">
          Date & Time
        </h3>
        <div class="text-tablet-xl">
          {{ formattedStartDate }} | {{ formattedStartTime }}
        </div>
        <UiButton
          v-if="fields.link"
          button-type="primary"
          button-theme="dark"
          class="mt-10"
        >
          <RichTextRenderer
            :document="fields.link"
            :node-renderers="nodeRenderers"
          />
        </UiButton>

        <UiAddToCalendar
          :title="fields.title"
          :calendar-description="fields.calendarDescription"
          :start-date-time="fields.startDateTime"
          :end-date-time="fields.endDateTime"
        />
      </CardHeader>
    </div>
  </UiContainer>
</template>
