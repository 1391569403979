<script lang="ts" setup>
import type { Entry } from "contentful";
import type { TypeStaffSkeleton } from "~/types/contentful";

const props = defineProps<{
  fields: Entry<TypeStaffSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  popup?: boolean;
}>();

const emit = defineEmits(["popup"]);
const staffPopup = () => {
  emit("popup", props.fields.slug);
};
</script>

<template>
  <div class="staff">
    <NuxtImg
      v-if="fields.thumbnail && fields.thumbnail.fields"
      class="rounded-xl object-cover"
      :class="popup && 'cursor-pointer'"
      :src="fields.thumbnail?.fields.file?.url"
      :alt="fields.thumbnail?.fields.description || 'staff image'"
      :width="480"
      :height="480"
      loading="lazy"
      @click="popup ? staffPopup() : null"
    />

    <div
      v-else
      class="h-full max-h-[260px] rounded-xl bg-light-grey"
    />

    <div class="mb-3 mt-6 flex items-center gap-3 lg:mt-9">
      <UiBadge
        bg-colour="#f4f4f5"
        class="!rounded-3xl !px-[14px] text-xl"
        :class="popup && 'cursor-pointer'"
        @click="popup ? staffPopup() : null"
      >
        {{ fields.name }}
      </UiBadge>

      <NuxtLink
        v-if="fields.linkedIn"
        :to="fields.linkedIn"
        target="_blank"
        class="flex size-8 items-center justify-center rounded-full bg-charcoal"
      >
        <NuxtIcon
          name="ussif:linkedin"
          class="text-white"
        />
      </NuxtLink>
    </div>

    <div class="ml-[14px]">
      {{ fields.jobTitle }}
    </div>
  </div>
</template>
