<script setup lang="ts">
import type { EntryCollection } from "contentful";
import {
  isTypeArticle,
  type TypeArticleSkeleton,
  type TypeCarouselArticles,
} from "~/types/contentful";

const props = defineProps<{
  fields: TypeCarouselArticles<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  relatedArticleIds?: (string | undefined)[] | undefined;
}>();

const articleIds = computed(() => {
  if (props.relatedArticleIds === undefined) {
    return props.fields.items?.map((item) => item?.sys.id);
  }
  return props.relatedArticleIds;
});

const { data: articles } = await useLazyFetch<
  EntryCollection<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/articles", {
  query: {
    ...(articleIds.value ? { articleIds: articleIds } : {}),
  },
  transform: useCircularProtect,
});

const settings = {
  itemsToShow: 2,
  snapAlign: "start",
  wrapAround: true,
};

const breakpoints = {
  0: {
    itemsToShow: 1,
  },
  768: {
    itemsToShow: 2,
  },
};

const isInitialised = ref(false);

const setInitialised = () => {
  isInitialised.value = true;
};
</script>

<template>
  <UiContainer :class="{ 'bg-navy text-white': !fields.whiteBackground }">
    <UiSectionTitle
      :title="props.fields.title"
      class="items-col !mb-4 flex flex-row justify-between"
    >
      <UiButton
        v-if="fields.link"
        button-type="secondary"
        button-icon="slash"
      >
        <NuxtLink to="/news">
          All news
        </NuxtLink>
      </UiButton>
    </UiSectionTitle>

    <div class="border-b-2 border-blue pb-4 md:border-b-[6px] md:pb-8">
      <Carousel
        v-bind="settings"
        :breakpoints="breakpoints"
        class="transition-opacity duration-300"
        :class="{
          'opacity-0': !isInitialised,
          'opacity-100': isInitialised,
        }"
        @init="setInitialised"
      >
        <Slide
          v-for="articleItem in articles?.items"
          :key="articleItem.sys.id"
        >
          <CardArticle
            v-if="articleItem && isTypeArticle(articleItem)"
            :article="articleItem"
          />
        </Slide>

        <template #addons>
          <div class="carousel__controls mt-2">
            <pagination />
            <div class="carousel__nav">
              <Navigation />
            </div>
          </div>
        </template>
      </Carousel>
    </div>
  </UiContainer>
</template>

<style scoped lang="scss">
::v-deep(.carousel__slide) {
  // align-items: flex-start;
  // padding-right: 20px;

  @media screen and (width >= 768px) {
    padding-right: 40px;
  }
}
</style>
