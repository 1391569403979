<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeHeaderCourse } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeHeaderCourse<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <UiContainer>
    <div class="grid grid-cols-12 gap-y-8">
      <div class="col-span-12 md:col-span-6">
        <h1>{{ fields.title }}</h1>

        <TextCourseInfo
          v-if="fields.duration || fields.rating"
          :duration="fields.duration"
          :rating="fields.rating"
        />

        <TextReadMore
          :text="fields.text"
          :extra-text="fields.extraText"
        />
      </div>

      <CardHeader>
        <h3 class="font-bold">
          {{ fields.cardTitle }}
        </h3>
        <h3 class="font-bold">
          {{ fields.price }}
        </h3>
        <UiButton
          v-if="fields.link"
          button-type="primary"
          button-theme="dark"
          class="mt-6 lg:mt-8"
        >
          <RichTextRenderer
            :document="fields.link"
            :node-renderers="nodeRenderers"
          />
        </UiButton>
        <div class="mt-4 flex flex-col gap-2 text-center lg:gap-3">
          <div class="mb-4 text-sm opacity-70">
            {{ fields.cardFooterTextOne }}
          </div>
          <div class="font-bold lg:text-tablet-xl">
            {{ fields.cardFooterTextTwo }}
          </div>
          <div class="lg:text-tablet-xl">
            {{ fields.cardFooterTextThree }}
          </div>
        </div>
      </CardHeader>
    </div>
  </UiContainer>
</template>
