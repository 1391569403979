<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockMediaText } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

const props = defineProps<{
  fields: TypeBlockMediaText<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const parseVimeoId = (url: string) => {
  if (url.includes("player.vimeo"))
    return url.split("https://player.vimeo.com/video/")[1]?.split("/")[0];
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const parseUrl = regExp.exec(url);
  return parseUrl?.[5] ? parseUrl[5] : "";
};

const vimeoId = computed(() => (props.fields.vimeoUrl ? parseVimeoId(props.fields.vimeoUrl) : ""));
</script>

<template>
  <UiContainer
    class="media-text"
    :no-padding="!fields.text"
    :no-horisontal-padding="!fields.text"
  >
    <UiSectionTitle
      v-if="fields.displayTitle"
      :title="fields.title"
      :subtitle="fields.subtitle"
    />

    <section :class="{ 'grid grid-cols-12 gap-y-6 lg:gap-10 xl:gap-24': fields.text }">
      <div
        class="mt__image col-span-12"
        :class="{
          'lg:order-2': !fields.mediaPosition,
          ' lg:col-span-6': fields.text,
        }"
      >
        <UiVimeoPlayer
          v-if="fields.vimeoUrl"
          ref="player"
          class="relative"
          :class="{ 'mb-10': !fields.text }"
          :video-id="Number(vimeoId)"
          :autoplay="false"
          :player-width="!fields.text ? 794 : undefined"
          :player-height="!fields.text ? 446 : undefined"
        />

        <NuxtImg
          v-else-if="fields.media"
          v-rellax
          data-rellax-xs-speed="0"
          data-rellax-speed="1"
          data-rellax-percentage="0.5"
          class="size-full rounded-xl object-cover"
          :class="{ 'lg:min-h-[409px]': fields.text }"
          :src="fields.media?.fields.file?.url"
          :alt="fields.media?.fields.title"
          :width="600"
          loading="lazy"
          quality="80"
          format="webp"
        />
      </div>

      <div
        v-if="fields.text"
        class="mt__text col-span-12 content-center items-center lg:col-span-6"
        :class="{ 'lg:order-1': !fields.mediaPosition }"
      >
        <div class="rich-text">
          <RichTextRenderer
            :document="fields.text"
            :node-renderers="nodeRenderers"
          />
        </div>

        <TextCourseInfo
          v-if="fields.duration || fields.rating"
          :duration="fields.duration"
          :rating="fields.rating"
        />

        <div
          v-if="fields.price"
          class="flex flex-row items-center justify-between lg:rounded-xl lg:bg-charcoal/[0.02] lg:p-6"
        >
          <UiButton
            v-if="fields.link"
            button-type="primary"
            button-style="outline"
            button-theme="light"
            button-icon="slash"
            class=""
          >
            <RichTextRenderer
              :document="fields.link"
              :node-renderers="nodeRenderers"
            />
          </UiButton>
          <div class="text-desktop-xl font-bold">
            {{ fields.price }}
          </div>
        </div>

        <UiButton
          v-if="fields.link && !fields.price"
          button-type="primary"
          button-style="outline"
          button-theme="light"
          button-icon="slash"
          class="mt-10"
        >
          <RichTextRenderer
            :document="fields.link"
            :node-renderers="nodeRenderers"
          />
        </UiButton>
      </div>
    </section>
  </UiContainer>
</template>
