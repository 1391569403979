<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeHeaderHome } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeHeaderHome<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <UiContainer class="relative overflow-hidden bg-navy !pb-0 text-white">
    <header class="relative min-h-[350px] grid-cols-12 gap-5 lg:grid lg:min-h-[610px]">
      <div class="relative z-[1] col-span-12 items-center lg:col-span-8 lg:flex">
        <div class="header-text">
          <h1 class="hero lg:text-[3.75rem]">
            {{ fields.title }}
          </h1>
          <div class="mt-5 lg:max-w-[680px] lg:text-2xl">
            {{ fields.subtitle }}
          </div>

          <UiButton
            v-if="fields.link"
            button-type="primary"
            button-style="outline"
            button-theme="dark"
            button-icon="slash"
            class="mt-10"
          >
            <RichTextRenderer
              :document="fields.link"
              :node-renderers="nodeRenderers"
            />
          </UiButton>
        </div>
      </div>

      <div class="absolute top-3 z-0 size-full">
        <div class="grid min-h-[433px] grid-cols-12 gap-5 py-5">
          <div
            class="col-span-8 col-start-7 ml-[-43%] mr-[-25%] items-center overflow-visible max-sm:absolute max-sm:right-[-260px] max-sm:-mt-3 max-sm:w-[500px] lg:col-span-7 lg:col-start-6 lg:ml-[-25%] lg:flex"
          >
            <div class="skew skew--with-border">
              <NuxtImg
                v-if="fields.image"
                v-rellax
                data-rellax-speed="1"
                data-rellax-percentage="0.5"
                class="object-cover"
                :src="fields.image.fields.file?.url"
                :alt="fields.image?.fields.title"
                :width="1128"
                :height="533"
                format="webp"
                quality="80"
              />
            </div>
          </div>
        </div>
      </div>
    </header>

    <div
      class="absolute left-[calc(35%-1000px)] top-3 z-0 w-[1000px] overflow-visible pt-5 lg:left-[calc(10%-1000px)]"
    >
      <div class="skew">
        <div class="min-h-[433px] bg-navy-light lg:min-h-[533px]" />
      </div>
    </div>
  </UiContainer>
</template>
