import type { Entry } from "contentful";
import type {
  TypeArticleSkeleton,
  TypePageSkeleton,
} from "~/types/contentful";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = useNuxtApp();

  // We don't need to load content if we've just received it via SSR
  if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
    return;
  }

  const routes = useRoutes();

  let toPath = to.path;

  // Remove trailing slashes, as sometimes they're hard-coded into certain contentful fields
  if (/.+\/$/.exec(toPath)) {
    // eslint-disable-next-line no-console
    console.warn(
      `Trailing slash in URL ${toPath} seen when navigating from ${from.path}`,
    );

    toPath = toPath.replace(/\/+$/, "");
  }

  const route = routes.value.find((route) => route.path === toPath);

  // If we have no route, aren't an API request and have hit the catch-all, we definitely have a 404
  if (
    !route
    && !to.path.startsWith("/api")
    && useRouter().resolve(to).name === "slug"
  ) {
    return abortNavigation(
      createError({
        statusCode: 404,
        statusMessage: "Page not found",
      }),
    );
  }

  if (route?.sysId) {
    const { data: content, error } = await useFetch<
      Entry<
        | TypeArticleSkeleton
        | TypePageSkeleton,
        "WITHOUT_UNRESOLVABLE_LINKS",
        "en-GB"
      >
    >("/api/content", {
      query: {
        id: route.sysId[0],
      },
      transform: useCircularProtect,
    });

    if (
      error.value?.statusCode
      && (error.value.statusCode >= 500 || error.value.statusCode === 404)
    ) {
      return abortNavigation(error.value);
    } else {
      useLoadedContent().value = content.value;
    }
  } else {
    useLoadedContent().value = null;
  }
});
