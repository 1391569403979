<script setup lang="ts">
import { ModalsContainer } from "vue-final-modal";

const metaTitleBase = useContentConfig().value?.metaTitleBase ?? "";

useSeoMeta({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${metaTitleBase}` : metaTitleBase;
  },
});
</script>

<template>
  <!-- smooth scrolling -->
  <Lenis root>
    <!-- Content -->
    <NuxtLayout>
      <NuxtPage />
      <ModalsContainer />
    </NuxtLayout>
  </Lenis>
</template>
