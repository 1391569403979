import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypeDownloadTypeFields = {
  title: EntryFieldTypes.Symbol;
  slug?: EntryFieldTypes.Symbol;
  image?: EntryFieldTypes.AssetLink;
};

export type TypeDownloadTypeSkeleton = EntrySkeletonType<TypeDownloadTypeFields, "downloadType">;
export type TypeDownloadType<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeDownloadTypeSkeleton, Modifiers, Locales>;

export function isTypeDownloadType<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeDownloadType<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "downloadType";
}
