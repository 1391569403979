<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockAccordion } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

const props = defineProps<{
  fields: TypeBlockAccordion<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const accordionItems = reactive(
  props.fields.items.map((item, index) => ({
    title: item?.fields.title,
    content: item?.fields.content,
    isExpanded: index === -1, // Initial values, display expanded on mount
  })),
);

function handleAccordion(selectedIndex: number) {
  accordionItems.forEach((item, index) => {
    item.isExpanded = index === selectedIndex ? !item.isExpanded : false;
  });
}
</script>

<template>
  <UiContainer>
    <UiSectionTitle
      v-if="fields.displayTitle"
      :title="fields.title"
      title-alignment="Left"
    />

    <section :class="{ 'grid grid-cols-12 gap-y-6 lg:gap-10 xl:gap-24': fields.text }">
      <div
        class="col-span-12"
        :class="{
          'order-2 lg:order-2': fields.accordionPosition === 'Right',
          'order-2 lg:order-1': fields.accordionPosition === 'Left',
          'lg:col-span-6': fields.text,
        }"
      >
        <div
          v-for="(item, index) in accordionItems"
          :key="index"
          class="mb-2 rounded-lg bg-charcoal/[0.05] p-4 md:p-6"
        >
          <div
            :class="[
              'relative flex cursor-pointer items-center gap-x-4 text-xl',
              {
                active: item.isExpanded,
              },
            ]"
            @click="handleAccordion(index)"
          >
            <div
              class="flex size-8 items-center justify-center rounded-full bg-blue transition lg:size-10"
              :class="{
                'rotate-180': item.isExpanded,
              }"
            >
              <NuxtIcon
                v-if="!item.isExpanded"
                name="ussif:plus"
                class="size-5 min-w-4 text-white lg:size-6"
              />
              <NuxtIcon
                v-else
                name="ussif:minus"
                class="size-5 min-w-4 text-white lg:size-6"
              />
            </div>
            {{ item.title }}
          </div>

          <Collapse
            as="section"
            :when="item.isExpanded"
          >
            <div class="rich-text pt-4 text-charcoal/55 lg:pl-14">
              <RichTextRenderer
                :document="item.content"
                :node-renderers="nodeRenderers"
              />
            </div>
          </Collapse>
        </div>
      </div>

      <div
        v-if="fields.text && fields.accordionPosition != 'Full'"
        class="order-1 col-span-12 content-center items-center"
        :class="{
          'lg:col-span-6': fields.text,
        }"
      >
        <div class="rich-text">
          <RichTextRenderer
            :document="fields.text"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </section>
  </UiContainer>
</template>
