<script lang="ts" setup>
import type { Asset } from "contentful";
import type { TypeBlockLogosCarousel } from "~/types/contentful";

const props = defineProps<{
  fields: TypeBlockLogosCarousel<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const logosArray: (Asset<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB"> | undefined)[][] = [];

const logos = props.fields.logos ?? [];
const amountPerRow = 40;
const rows = Math.ceil(logos.length / amountPerRow);

for (let index = 0; index < rows; index++) {
  logosArray.push(logos.slice(index * amountPerRow, (index + 1) * amountPerRow));
}
</script>

<template>
  <UiContainer
    class="overflow-hidden pb-12 md:pb-24"
    :no-padding="true"
  >
    <h2
      v-if="fields.displayTitle"
      class="pt-10 text-center lg:pt-24"
    >
      {{ fields.title }}
    </h2>

    <div
      v-for="(row, index) in logosArray"
      :key="index"
    >
      <CarouselLogosRow
        :images="
          row.filter(
            (logo): logo is Asset<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'> => logo !== undefined,
          )
        "
        :index="index"
      />
    </div>
  </UiContainer>
</template>
