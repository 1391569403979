<script lang="ts" setup>
import type { TypeBlockAgenda } from "~/types/contentful";
import { formatDate } from "~/lib/date-time";

defineProps<{
  fields: TypeBlockAgenda<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const selectedTabNumber = ref(0);
</script>

<template>
  <UiContainer
    :class="{
      'bg-navy text-white': fields.theme === 'Dark',
    }"
  >
    <UiSectionTitle
      v-if="fields.displayTitle"
      :title="fields.title"
    />

    <!-- tabs -->
    <div class="flex flex-row gap-y-4 overflow-auto md:justify-center">
      <div
        v-for="(agendaDay, index) in fields.agendaDays"
        :key="index"
        class="w-max cursor-pointer border-b-4 px-6 pb-2 transition-all hover:opacity-100 md:px-8 md:text-center lg:px-20 lg:pb-8"
        :class="{
          'border-b-blue opacity-100': selectedTabNumber === index,
          'border-b-white/30 opacity-50': fields.theme === 'Dark' && selectedTabNumber != index,
          'border-b-black/20 opacity-50': fields.theme === 'Light' && selectedTabNumber != index,
          'pl-0': index === 0,
        }"
        @click="selectedTabNumber = index"
      >
        <h4>
          {{ agendaDay?.fields?.title }}
        </h4>
        <div class="whitespace-nowrap">
          {{ agendaDay?.fields?.date ? formatDate(agendaDay.fields.date) : "" }}
        </div>
      </div>
    </div>

    <!-- tabs content -->
    <div
      v-for="(agendaDay, index) in fields.agendaDays"
      :key="index"
      class="mt-4 md:mt-10"
    >
      <BlockAgendaDay
        v-if="selectedTabNumber === index && agendaDay"
        :item="agendaDay!"
        :theme="fields.theme || 'Dark'"
      />
    </div>
  </UiContainer>
</template>
