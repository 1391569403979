<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockWysiwyg } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeBlockWysiwyg<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <UiContainer>
    <UiSectionTitle
      v-if="fields.displayTitle"
      :title="fields.title"
    />
    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-8 md:col-start-3">
        <div class="wysiwyg">
          <RichTextRenderer
            :document="fields.wysiwygContent"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </div>
  </UiContainer>
</template>
