<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  type TypeTopicSkeleton,
  type TypeSubtopicSkeleton,
  isTypeTopic,
  isTypeSubtopic,
} from "~/types/contentful";

const props = defineProps<{
  topicOrSubtopics: Entry<
    TypeTopicSkeleton | TypeSubtopicSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >[];
  title?: string;
}>();
</script>

<template>
  <div
    v-if="props.title"
    class="font-bold"
  >
    {{ props.title }}
  </div>

  <template
    v-for="(topicOrSubtopic, index) in topicOrSubtopics"
    :key="index"
  >
    <!-- <NuxtLink
      :to="
        isTypeTopic(topicOrSubtopic)
          ? `/themes/${topicOrSubtopic.fields.slug}`
          : isTypeSubtopic(topicOrSubtopic) && topicOrSubtopic.fields.topic?.fields.slug
            ? `/themes/${topicOrSubtopic.fields.topic.fields.slug}`
            : ''
      "
    >
      {{ topicOrSubtopic.fields.title }}
      <div
        class="h-[2px]"
        :style="{ backgroundColor: isTypeTopic(topicOrSubtopic)
          ? topicOrSubtopic.fields.colour
          : isTypeSubtopic(topicOrSubtopic)
            ? topicOrSubtopic.fields.topic?.fields.colour
            : undefined }"
      />
    </NuxtLink> -->
    <div class="ms-2">
      {{ topicOrSubtopic.fields.title }}
      <div
        class="h-[2px]"
        :style="{ backgroundColor: isTypeTopic(topicOrSubtopic)
          ? topicOrSubtopic.fields.colour
          : isTypeSubtopic(topicOrSubtopic)
            ? topicOrSubtopic.fields.topic?.fields.colour
            : undefined }"
      />
    </div>
  </template>
</template>
