<script setup lang="ts">
const contentConfig = useContentConfig();
</script>

<template>
  <UiContainer
    :no-padding="true"
    class="z-20 lg:bg-navy"
  >
    <NavigationMain
      v-if="contentConfig?.mainNavigation"
      v-bind="contentConfig?.mainNavigation"
    />
  </UiContainer>
</template>
