<script lang="ts" setup>
import type {
  TypeBlockTabbedList,
  TypeBlockTabbedListFields,
} from "~/types/contentful";

type KeyValue = { key: string; value: string };

defineProps<{
  fields: TypeBlockTabbedList<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"] & {
    tab1Items: KeyValue[];
    tab2Items: KeyValue[];
    tab3Items: KeyValue[];
    tab4Items: KeyValue[];
    tab5Items: KeyValue[];
    tab6Items: KeyValue[];
  };
}>();

const selectedTabNumber = ref(1);
</script>

<template>
  <UiContainer class="block-tabbed-list">
    <h2>
      {{ fields.title }}
    </h2>

    <div class="mb-6 mt-8 grid grid-cols-12 gap-y-4 md:gap-x-16 lg:mt-12">
      <div
        class="col-span-12 max-lg:-mx-6 max-lg:w-[calc(100%+48px)] max-lg:overflow-auto max-lg:pl-6 lg:col-span-4"
      >
        <div class="max-lg:flex">
          <div
            v-for="(tabNumber, index) in 6"
            :key="index"
            class="cursor-pointer whitespace-nowrap border-[#e9e9eb] px-7 py-3 max-lg:border-b-4 lg:border-l-4"
            :class="{
              '!border-blue font-bold text-blue':
                selectedTabNumber === tabNumber,
              'hover:text-blue': selectedTabNumber !== tabNumber,
            }"
            @click="selectedTabNumber = tabNumber"
          >
            {{ fields[`tab${tabNumber}` as keyof TypeBlockTabbedListFields] }}
          </div>
        </div>
      </div>

      <div
        v-if="
          fields[
            `tab${selectedTabNumber}Items` as keyof TypeBlockTabbedListFields
          ]
        "
        class="col-span-12 max-lg:mt-6 lg:col-span-8"
      >
        <div class="grid gap-12 md:grid-cols-2">
          <template
            v-for="(item, index) in fields[
              `tab${selectedTabNumber}Items` as keyof TypeBlockTabbedListFields
            ]"
            :key="index"
          >
            <div v-if="item">
              <div class="mb-3 text-xl font-bold">
                {{ (item as KeyValue).key }}
              </div>
              <div>
                {{ (item as KeyValue).value }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </UiContainer>
</template>
