
import * as contentfulRuntime$4NlPHdz1KS from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/contentful'

export const imageOptions = {
  "screens": {
    "xs": 0,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1200,
    "xxl": 1400,
    "2xl": 1536
  },
  "presets": {},
  "provider": "contentful",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['contentful']: { provider: contentfulRuntime$4NlPHdz1KS, defaults: {} }
}
        