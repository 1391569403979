const monthNames = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getMonth = (dateString = "") =>
  monthNames[new Date(dateString).getMonth()];

export const getDay = (dateString = "") =>
  new Date(dateString).getDate();

export const getYear = (dateString = "") =>
  new Date(dateString).getFullYear();

export const formatDate = (dateString: string, noYear = false) => {
  const date = new Date(dateString);

  return [
    date.getDate(),
    date.toLocaleDateString("en-GB", {
      month: "long",
    }),
    ...(noYear ? [] : [date.getFullYear()]),
  ].join(" ");
};

export const formatTime = (dateString: string) => {
  const date = new Date(dateString);

  return `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes(),
  ).padStart(2, "0")}`;
};
