<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockParallaxImage } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

const props = defineProps<{
  fields: TypeBlockParallaxImage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const container = ref(null);
const stickySkew = ref(null);
const orderIndex = props.fields.orderIndex;

// responsive
const mm = useGsap.matchMedia();

let endPosition;

switch (orderIndex) {
  case 1:
    endPosition = "bottom top";
    break;
  case 4:
    endPosition = "bottom bottom";
    break;
  default:
    endPosition = `${String(orderIndex)}00% bottom`;
}

onMounted(() => {
  mm.add("(min-width: 1024px)", () => {
    if (orderIndex > 1) {
      useGsap.set(stickySkew.value, { yPercent: -100 });
    }

    if (orderIndex > 0) {
      useGsap.to(stickySkew.value, {
        ease: "power1.out",
        scrollTrigger: {
          trigger: container.value,
          start: orderIndex === 1 ? "center center" : "top bottom",
          end: endPosition,
          pin: true,
        },
      });
    }
  });
});
</script>

<template>
  <UiContainer
    :no-padding="true"
    class="parallax relative overflow-hidden text-white lg:h-screen"
  >
    <div
      v-rellax
      data-rellax-speed="1"
      data-rellax-percentage="-0.5"
      class="absolute inset-0 size-full"
    >
      <NuxtImg
        v-if="fields.backgroundImage?.fields"
        class="h-[130%] w-full object-cover"
        :src="fields.backgroundImage?.fields.file?.url"
        :alt="fields.backgroundImage?.fields.title"
        sizes="sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
        loading="lazy"
        densities="x1"
        quality="80"
        format="webp"
      />
    </div>
    <div class="absolute inset-0 size-full bg-black opacity-50" />
    <div ref="container">
      <section
        ref="stickySkew"
        class="relative grid grid-cols-12 items-center gap-y-20 py-10 md:gap-x-10 lg:h-screen"
      >
        <div class="col-span-12 md:col-span-6 lg:col-span-7">
          <h3 class="mb-4">
            {{ fields.subtitle }}
          </h3>
          <h2 class="h1 mb-8">
            {{ fields.title }}
          </h2>

          <div class="text-tablet-xl">
            <RichTextRenderer
              :document="fields.content"
              :node-renderers="nodeRenderers"
            />
          </div>

          <UiButton
            v-if="fields.link"
            button-type="secondary"
            button-icon="slash"
            class="mt-10"
          >
            <RichTextRenderer
              :document="fields.link"
              :node-renderers="nodeRenderers"
            />
          </UiButton>
        </div>
        <div class="relative col-span-12 md:col-span-6 lg:col-span-5">
          <div class="skew-clip flex justify-center">
            <NuxtImg
              v-if="fields.parallaxImage?.fields"
              class="h-[244px] w-[203px] md:h-[613px] md:w-[510px]"
              :src="fields.parallaxImage?.fields.file?.url"
              :alt="fields.parallaxImage?.fields.title"
              sizes="203px md:510px"
              loading="lazy"
              densities="x1"
              quality="80"
              format="webp"
            />
          </div>
        </div>
      </section>
    </div>
  </UiContainer>
</template>
