import { h } from "vue";
import {
  type AssetLinkBlock,
  type EntryLinkBlock,
  type EntryLinkInline,
  type Inline,
  // type Paragraph,
  type Text,
  BLOCKS,
  INLINES,
} from "@contentful/rich-text-types";
import { NuxtLink } from "#components";
import embeddedEntryResolver from "~/lib/embedded-entry-resolver.js";

const getComponent = (node: EntryLinkBlock | EntryLinkInline) => {
  const { component, props } = embeddedEntryResolver(node);

  if (!component) {
    return h("p", {
      innerHTML: `<p>Broken data at "${node.nodeType}", has the embedded entry been published?</p>`,
    });
  }

  if (typeof component === "string") {
    return h("p", {
      innerHTML: `Unknown component key "${component}"`,
    });
  }

  return h(component, props);
};

export default {
  [BLOCKS.EMBEDDED_ENTRY]: (node: EntryLinkBlock) => getComponent(node),

  // [BLOCKS.PARAGRAPH]: (node: Paragraph) =>
  //   h(
  //     "p",
  //     {
  //       innerHTML: node.content.map((p: Inline | Text) => "value" in p ? p.value.replace("\n", "<br><br>") : ""),
  //     },
  //     [h("p")],
  //   ),

  [BLOCKS.HR]: () =>
    h(
      "div",
      {
        class: "container px-3 md:px-4 lg:px-5",
        innerHTML: "<hr/>",
      },
      [h("div")],
    ),

  [INLINES.EMBEDDED_ENTRY]: (node: EntryLinkInline) => getComponent(node),

  [INLINES.ENTRY_HYPERLINK]: (node: Inline) => {
    let href = "";

    if (node.data.target.fields.externalLink) {
      href = node.data.target.fields.externalLink;
    } else {
      // There can be links to missing routes embedded in entry content, so be sure to catch them
      try {
        href = getUrl(node.data.target);
      } catch {
        // Do nothing
      }
    }

    return h(NuxtLink, {
      to: href,
      innerHTML: (node.content[0] as Text).value,
    });
  },

  [INLINES.ASSET_HYPERLINK]: (node: Inline) => {
    const href = node.data.target.fields.file.url;

    return h(NuxtLink, {
      to: href,
      innerHTML: (node.content[0] as Text).value,
    });
  },

  [BLOCKS.EMBEDDED_ASSET]: (node: AssetLinkBlock) =>
    h(
      "div",
      {
        class: "flex justify-center",
      },
      [
        h("img", {
          src:
            // @ts-expect-error "fields" property is not included in target for some reason
            String(node.data.target.fields.file.url)
            // @ts-expect-error "fields" property is not included in target for some reason
            + `?fit=scale&w=${Number(node.data.target.fields.file.details.image.width) > 1320 ? "1320" : String(node.data.target.fields.file.details.image.width)}&fm=webp`,
        }),
      ],
    ),
};
