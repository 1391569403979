import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _10_45redirects_45global from "/opt/build/repo/middleware/10.redirects.global.ts";
import _20_45load_45config_45global from "/opt/build/repo/middleware/20.load-config.global.ts";
import _25_45load_45routes_45global from "/opt/build/repo/middleware/25.load-routes.global.ts";
import _40_45load_45content_45global from "/opt/build/repo/middleware/40.load-content.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _10_45redirects_45global,
  _20_45load_45config_45global,
  _25_45load_45routes_45global,
  _40_45load_45content_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}