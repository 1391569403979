<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import FooterSubscribe from "./other/FooterSubscribe.vue";
import nodeRenderers from "~/lib/node-renderers.js";

const contentConfig = useContentConfig();
</script>

<template>
  <UiContainer
    :no-padding="true"
    class="bg-navy"
  >
    <footer class="my-12 py-6 text-white">
      <div class="container mx-auto py-4 xl:px-0">
        <div class="flex w-full flex-col md:flex-row md:space-x-8">
          <div class="md:w-1/2">
            <a
              href="/"
              class="block w-40 text-nowrap font-bold "
            >
              <NuxtIcon
                name="ussif:logo"
                class="size-full"
              />
            </a>
            <div class="flex w-full flex-row md:space-x-8">
              <RichTextRenderer
                v-if="contentConfig?.footer"
                :document="contentConfig.footer"
                :node-renderers="nodeRenderers"
              />
            </div>
          </div>

          <FooterSubscribe />
        </div>

        <div class="mt-4 flex flex-col items-center justify-center border-t pt-4 md:flex-row md:justify-between">
          <nav class="mb-3 flex space-x-4 pr-4 md:mb-0 xl:pr-40">
            <a
              href="https://www.ussif.org/privacypolicy"
              class="btn--link w-fit text-sm text-white"
            >Privacy Policy</a>
            <!-- <a
              href="/terms"
              class="text-xs"
            >Terms and Conditions</a>
            <a
              href="/cookies"
              class="text-xs"
            >Cookie Policy</a> -->
          </nav>
          <div class="flex flex-row items-center gap-x-4">
            <p class="text-sm">
              Copyright © 2024 US SIF. All rights reserved.
            </p>
            <a href="https://twitter.com/US_SIF">
              <NuxtIcon
                name="ussif:x"
                class=""
              />
            </a>
            <a href="https://www.linkedin.com/company/us-sif/?viewAsMember=true">
              <NuxtIcon
                name="ussif:linkedin"
                class=""
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  </UiContainer>
</template>
