import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeSubtopicFields = {
  title: EntryFieldTypes.Symbol;
  topic: EntryFieldTypes.EntryLink<TypeTopicSkeleton>;
};

export type TypeSubtopicSkeleton = EntrySkeletonType<TypeSubtopicFields, "subtopic">;
export type TypeSubtopic<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeSubtopicSkeleton, Modifiers, Locales>;

export function isTypeSubtopic<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeSubtopic<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "subtopic";
}
