import type { Entry } from "contentful";
import type {
  TypeArticleSkeleton,
  TypePageSkeleton,
} from "~/types/contentful";

export default <
  T = Entry<
    | TypeArticleSkeleton
    | TypePageSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >,
>() => useState<T | null>("loadedContent", () => shallowRef(null));
