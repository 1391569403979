<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  isTypeArticle,
  isTypeDownload,
  type TypeArticleSkeleton,
  type TypeDownloadSkeleton,
} from "~/types/contentful";
import { formatDate } from "~/lib/date-time";

const props = defineProps<{
  item: Entry<TypeArticleSkeleton | TypeDownloadSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();

const type = computed(() => {
  if (isTypeArticle(props.item)) {
    return props.item.fields.articleType?.fields.title;
  }

  if (isTypeDownload(props.item)) {
    return props.item.fields.downloadType?.fields.title;
  }

  return undefined;
});

const date = computed(() => {
  if (isTypeDownload(props.item)) {
    return formatDate(new Date(props.item.fields.date).toISOString());
  } else {
    return undefined;
  }
});
</script>

<template>
  <section class="w-full rounded-xl bg-navy p-6 text-white lg:p-8">
    <div class="flex h-full flex-col justify-between gap-4">
      <div>
        <div class="mb-4 flex items-center">
          <NuxtIcon
            class="mr-4 text-blue"
            name="ussif:video-camera"
          />
          <UiDate
            :date="date"
            hide-slash
          />
        </div>

        <div class="flex flex-col">
          <h3 class="mb-5 text-left">
            <NuxtLink
              v-if="
                isTypeDownload(item) && (item.fields.file?.fields.file?.url || item.fields.videoUrl)
              "
              :to="item.fields.file?.fields.file?.url || item.fields.videoUrl"
            >
              {{ item.fields.title }}
            </NuxtLink>
          </h3>

          <p
            v-if="isTypeDownload(item)"
            class="mb-4"
          >
            {{ item.fields.shortTitle }}
          </p>

          <div class="mb-4 flex items-center">
            <UiBadge
              v-if="type"
              bg-colour="#e8e8e8"
            >
              {{ type }}
            </UiBadge>
          </div>
        </div>
      </div>

      <div class="mt-5 flex h-fit items-center justify-between lg:mt-10">
        <UiButton
          v-if="
            isTypeDownload(item) && (item.fields.file?.fields.file?.url || item.fields.videoUrl)
          "
          button-type="primary"
          button-style="outline"
          button-theme="dark"
          button-icon="slash"
        >
          <NuxtLink
            :to="item.fields.file?.fields.file?.url || item.fields.videoUrl"
            class=""
          >
            View
          </NuxtLink>
        </UiButton>

        <div
          v-if="isTypeDownload(item) && item.fields.videoLength"
          class="flex items-center"
        >
          <NuxtIcon
            class="mr-2 text-blue"
            name="ussif:clock"
          />
          {{ item.fields.videoLength }}
        </div>
      </div>
    </div>
  </section>
</template>
