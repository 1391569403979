<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeHighlight } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeHighlight<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <div class="flex gap-6 pt-4 lg:max-w-[80%] lg:pt-8">
    <div
      class="flex size-8 min-w-8 items-center justify-center rounded-full"
      :class="`bg-${fields.iconColour?.toLowerCase()}`"
    >
      <NuxtIcon
        name="ussif:check"
        class="text-sm text-white"
      />
    </div>
    <div>
      <h4 class="mb-4">
        {{ fields.title }}
      </h4>
      <div class="">
        <RichTextRenderer
          :document="fields.text"
          :node-renderers="nodeRenderers"
        />
      </div>
    </div>
  </div>
</template>
