<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockBannerWide } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeBlockBannerWide<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <UiContainer class="relative bg-cream max-md:pt-0 lg:min-h-[463px]">
    <div class="right-0 top-0 h-full max-md:-mx-8 max-md:mb-8 md:absolute md:w-1/2">
      <NuxtImg
        v-if="fields.image?.fields"
        class="size-full object-cover object-left md:[clip-path:polygon(180px_0%,100%_0%,100%_100%,0%_100%)]"
        :src="fields.image?.fields.file?.url"
        :alt="fields.image?.fields.title"
        :width="1280"
        loading="lazy"
      />
    </div>
    <div class="grid grid-cols-12 items-center gap-x-4">
      <div class="col-span-12 flex flex-col justify-center md:col-span-6">
        <h3>
          {{ fields.title }}
        </h3>

        <RichTextRenderer
          :document="fields.text"
          :node-renderers="nodeRenderers"
        />

        <UiButton
          v-if="fields.link"
          button-type="primary"
          button-style="outline"
          button-theme="light"
          button-icon="slash"
          class="mt-10"
        >
          <RichTextRenderer
            :document="fields.link"
            :node-renderers="nodeRenderers"
          />
        </UiButton>
      </div>
    </div>
  </UiContainer>
</template>
