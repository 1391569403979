<script lang="ts" setup>
import type { TypeDownloadSkeleton } from "~/types/contentful";

defineProps<{
  items: TypeDownloadSkeleton<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const carouselSettings = {
  itemsToShow: 1,
  itemsToScroll: 1,
  wrapAround: true,
  snapAlign: "center",
};

const breakpoints = {
  768: {
    itemsToShow: 2,
    snapAlign: "start",
  },
  1024: {
    itemsToShow: 3,
  },
};
</script>

<template>
  <carousel
    v-bind="carouselSettings"
    :breakpoints="breakpoints"
  >
    <slide
      v-for="item in items"
      :key="item.sys.id"
      class="px-2 py-4 lg:px-2 lg:py-10 xl:px-4"
    >
      <CardReport
        v-if="
          item.fields.downloadType.fields.title === 'Annual Report'
            || item.fields.downloadType.fields.title === 'Policy Resource'
        "
        :item="item"
      />
      <CardWebinar
        v-if="item.fields.downloadType.fields.title === 'Webinar'"
        :item="item"
      />
    </slide>

    <template #addons>
      <div class="carousel__controls">
        <pagination />
        <div class="carousel__nav">
          <navigation />
        </div>
      </div>
    </template>
  </carousel>
</template>

<style scoped lang="scss">
::v-deep(.carousel__viewport) {
  @apply overflow-visible lg:overflow-hidden;
}

// ::v-deep(.carousel__prev),
// ::v-deep(.carousel__next) {
//   @apply sm:flex hidden;
// }
</style>
