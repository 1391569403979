import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypeCardTestimonialFields = {
  quote: EntryFieldTypes.Symbol;
  author: EntryFieldTypes.Symbol;
  role?: EntryFieldTypes.Symbol;
};

export type TypeCardTestimonialSkeleton = EntrySkeletonType<TypeCardTestimonialFields, "cardTestimonial">;
export type TypeCardTestimonial<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeCardTestimonialSkeleton, Modifiers, Locales>;

export function isTypeCardTestimonial<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeCardTestimonial<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "cardTestimonial";
}
