import type { TypeRoutes } from "~/types/TypeRoutes";

export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp();

  if (
    import.meta.client
      && (!nuxtApp.isHydrating || nuxtApp.payload.serverRendered)
  ) {
    return;
  }

  const { data: routes, error } = await useFetch<TypeRoutes>("/api/routes");

  if (routes.value) {
    useRoutes().value = routes.value;
  } else if (error.value) {
    return abortNavigation(error.value);
  }
});
